
import React from 'react'

import { BlogCardT } from './_types'
import Icon from 'src/components/UI/MarkdownIcon'
import * as S from './_style'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'

type BlogCardProps = {
  card: BlogCardT;
  onCardClick: () => void;
  showImage?: boolean;
};

const BlogCard = ({ card, onCardClick, showImage = true }: BlogCardProps) => {
  return (
    <S.CardWrapper
      className='card_wrapper'
      href={card.link}
      target='_blank'
      rel='noreferrer'
      onClick={onCardClick}
      aria-label={card.title}
    >
      {showImage && (
        <S.ImageWrapper className='card_wrapper__image_wrapper'>
          <ImageWebpWithSizes className='card_wrapper__image_wrapper__image' pathSrc={card.image} heights={{ sm: 244 }} altDescription='' aria-hidden='true' />
        </S.ImageWrapper>
        )}
      <S.CategoriesHeader className='card_wrapper__categories_header'>
        <S.CategoryLabel className='card_wrapper__categories_header__label' dangerouslySetInnerHTML={{ __html: card.category || '' }} />
      </S.CategoriesHeader>
      <S.TitleWrapper className='card_wrapper__title_wrapper'>
        <h3 className='card_wrapper__title_wrapper__title font-citrina fs-20 lh-24 fw-500 text-grayscale--500 mb-2' dangerouslySetInnerHTML={{ __html: card.title }} />
        <span className='card_wrapper__title_wrapper__date fs-12 lh-15 fw-700 text-grayscale--300'>{card.date}</span>
      </S.TitleWrapper>
      <S.TextWrapper className='card_wrapper__text_wrapper'>
        <p className='card_wrapper__text_wrapper__description fs-16 lh-20 text-grayscale--400 mb-2' dangerouslySetInnerHTML={{ __html: card.description || '' }} />
        <div className='mt-2 card_wrapper__button_wrapper'>
          <BlogCard.ReadMoreBtn />
        </div>
      </S.TextWrapper>
    </S.CardWrapper>
  )
}

BlogCard.ReadMoreBtn = () => (
  <span
    title='Leia mais'
    className='card_wrapper__button_wrapper__read_more fs-14 lh-17 fw-400 text-orange--extra text-right d-block w-100'
  >
    Leia mais
    <Icon className='card_wrapper__button_wrapper__read_more__icon' width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
  </span>
)

export default BlogCard
